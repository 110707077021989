import ApplicationController from './application_controller'


import { get, post, put, patch, destroy } from '@rails/request.js'


/* This is the custom StimulusReflex controller for ExampleReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  setCommit(v) {
    document.getElementById('commit').value=v
  }
  setCommitCI() {
    this.setCommit("Clock In")
  }
  setCommitCO() {
    this.setCommit("Clock Out")
  }

  submitNow(e) {
    e.preventDefault();
    
    var form = document.getElementById('punchcard');

    var formData = new FormData(form);
    var canvas = document.getElementById('canvas');
    var context = canvas.getContext('2d');
    var video = document.getElementById('video');

    var file;

    var width = 320;
    var height = 240;

    canvas.width = width;
    canvas.height = height;

    context.drawImage(video, 0, 0, width, height);

    console.log("Canvas", canvas);
  
    canvas.toBlob( function(blob) { 
        console.log("Blob", blob);
        var file = new File([blob], 'snap.jpg', { 
          type: 'image/jpeg'
        }); 
        formData.append("image", file);

        var formKeys    = formData.keys();
        var formEntries = formData.entries();

        do {
          console.log("Entry",formEntries.next().value);
        } while (!formKeys.next().done);

        document.getElementsByClassName("spinner")[0].classList.remove("hidden");

        console.log("FD",formData);

        post('/', { body: formData  }).then((response) => {
          if (!response.ok) {
            console.log(response);
            alert("Request failed:" + JSON.stringify(response, null, 2));
            document.getElementsByClassName("spinner")[0].classList.add("hidden");
            var n = Array.from(document.getElementsByClassName("submitbtn"));
            for (const b of n) {
               b.disabled=false; 
            }
          } else {
            document.getElementsByClassName("spinner")[0].classList.add("hidden");
            Turbo.visit("/", { action: "replace" })
          }
        })
        //Rails.ajax({
          //type: "post",
          //url: "/",
          //data: formData,
          //error: (e) => {
            //console.log(e);
            //alert("Request failed:" + JSON.stringify(e, null, 2));
            //document.getElementsByClassName("spinner")[0].classList.add("hidden");
            //var n = Array.from(document.getElementsByClassName("submitbtn"))
            //for (const b of n) {
               //b.disabled=false; 
            //}
          //}
        //});
      }, 'image/jpeg')
    //document.getElementById('punchcard').submit()
  }

  connect() {
    var width = 320;    // We will scale the photo width to this
    var height = 0;     // This will be computed based on the input stream

    var streaming = false;

    var video = null;
    var canvas = null;

    video = document.getElementById('video');
    canvas = document.getElementById('canvas');

    navigator.mediaDevices.getUserMedia({video: true, audio: false})
    .then(function(stream) {
      video.srcObject = stream;
      //video.width=480;
      //video.height=320;
      //video.onloadedmetadata = function(e) {
            //video.play();
            //video.width=480;
            //video.height=320;
      //};
      video.play();
    })
    .catch(function(err) {
      alert("An error occurred: " + err);
    });

    video.addEventListener('canplay', function(ev){
      if (!streaming) {
        height = video.videoHeight / (video.videoWidth/width);
      
        if (isNaN(height)) {
          height = width / (4/3);
        }
      
        video.setAttribute('width', width);
        video.setAttribute('height', height);
        canvas.setAttribute('width', width);
        canvas.setAttribute('height', height);
        streaming = true;
      }
    }, false);
    console.log("Checking setup");
  const checkOnlineStatus = async () => {
    try {
       var dt = new Date( );
      const online = await fetch("/apple-touch-icon.png?s="+ dt.getMilliseconds() );
      return online.status >= 200 && online.status < 300; // either true or false
    } catch (err) {
      return false; // definitely offline
    }
  };
    this.online_interval = setInterval(async () => {
  const result = await checkOnlineStatus();
  const statusDisplay = document.getElementById("online");
  statusDisplay.textContent = result ? "Online" : "Offline";
}, 3000);

  }


  disconnect () {
    clearInterval(this.online_interval);
  }
}
