// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import "@hotwired/turbo-rails"


export default class extends Controller {
  static targets = [ "field" ]

  key (e) {
    event.preventDefault()
    this.fieldTarget.value = this.fieldTarget.value + e.target.dataset.numpadValue
    this.resetclock();
  }

  del (e) {
    event.preventDefault()
    this.fieldTarget.value = this.fieldTarget.value.slice(0 , -1)
    this.resetclock();
  }

  clear (e) {
    event.preventDefault()
    this.fieldTarget.value = ""
    this.resetclock();
  }

  resetclock() {
      clearTimeout(this.countdown)
      this.startCountDown()
      console.log("Reset counter")
  }

  reload() {
    Turbo.visit(window.location.toString(), { action: 'replace' })
  }

  startCountDown() {
    this.countdown = setTimeout(() => {
      this.reload();
    }, 300000)
  }

  connect() {
    this.startCountDown()
  }
}
