import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    console.log("Hello, Stimulus!", this.element)
  }

  toggleCalendar(event){
    $('#calendar_dropdown').toggle();
  }
}


