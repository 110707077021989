// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//  COMPARE

//require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
global.jQuery=global.$ = require('jquery') 

require("chartkick")
require("chart.js")

import "@hotwired/turbo-rails";


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"
//require("css/application.scss")




require("jquery-jeditable")
require("jquery-jeditable/dist/jquery.jeditable.time.min.js")


//document.addEventListener("turbolinks:load", function() {
  //console.log('foundation');
  //$(document).foundation()
//})
document.addEventListener("turbo:load", function() {
  console.log('reiniting editable');
  $('.edittr').editable(
    function(value, settings) {
      console.log('saving');
      document.getElementById('attendance')['attendance'].saveTR(this,value);
      return(value);
    },{
      width: '56px'
    }
  );
})




import "stylesheets/application.scss"

const originalWebSocketClose = WebSocket.prototype.close
WebSocket.prototype.close = function () {
  if (this.readyState != WebSocket.CONNECTING) {
    originalWebSocketClose.apply(this, arguments)
  }
}


$(document).on("turbolinks:load", () => {
  console.log("turbolinks!");
});
$(document).on("turbo:load", () => {
  console.log("turbo!");
});

