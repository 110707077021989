import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for DashboardReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  connect () {
	  console.log('this is it');
	    StimulusReflex.register(this);
	  
  }

  afterReflex(element, reflex) {
	   console.log('heelo');
  Chartkick.eachChart( function(chart) { chart.redraw(); console.log('redraw'); })
   }

  // updateError(element, reflex, error) {
  //   console.error('updateError', error);
  //   element.innerText = 'Update Failed!'
  // }
}
