import ApplicationController from './application_controller'
import StimulusReflex from 'stimulus_reflex'

import { DateTime } from "luxon";


export default class extends ApplicationController {
	  connect () {
		    StimulusReflex.register(this)
		  
		this.element[this.identifier] = this
	  }
	  saveTR(element,value) {
		  console.log("SAVETR",value);
	      console.log(this.stimulate("AttendanceReflex#savetrx",element,value));
	  }
	  edit (event) {
	    console.log(event.target.dataset)
	if (event.target.dataset.trid) {
        $('#trEditorTR').val(event.target.dataset.trid)
        $('#trEditorStart').val(event.target.dataset.time)
        $('#trEditorDirection').val(event.target.dataset.direction)

        $('#trEditor').foundation('open')
      }
	  }
	  hideform (event) {
      $('#recordEditor').hide()
    }
	  saveTRForm (event) {
      event.preventDefault()
      $('#recordEditor').hide()

      var tr_id = $("#recordEditor input[name=id]").val()
      var adj_time_in_time = $("#recordEditor input[name=adj_time_in_time]").val()
      var adj_time_out_time = $("#recordEditor input[name=adj_time_out_time]").val()
      var adj_time_in_date = $("#recordEditor input[name=adj_time_in_date]").val()
      var adj_time_out_date = $("#recordEditor input[name=adj_time_out_date]").val()

      //console.log(tr_id, adj_time_in_time, adj_time_out_time)

      const params = {
        id: tr_id,
        adj_time_in_time: adj_time_in_time,
        adj_time_out_time: adj_time_out_time,
        adj_time_in_date: adj_time_in_date,
        adj_time_out_date: adj_time_out_date
      };

      //console.log(params)

      const options = {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
          },
          method: 'POST',
          body: JSON.stringify( params )  
      };

      fetch('/attendance/save_time_record', options).then( data => {
        return data.json() 
      }).then( 
        json => {
          //console.log(json)
          //console.log($('[data-direction="In"][data-trid="'+json.tr.id+'"]'));
          $('[data-direction="In"][data-trid="'+json.tr.id+'"]').html(DateTime.fromISO(json.tr.adj_time_in).toFormat("HH:mm (d MMM)"));
          $('[data-direction="Out"][data-trid="'+json.tr.id+'"]').html(DateTime.fromISO(json.tr.adj_time_out).toFormat("HH:mm (d MMM)"));
        }
      )
      //commits => { 
      //var commitsHTML = "";
      //commits.forEach((commitJSON) => {
        //commitsHTML += commitTemplate(commitJSON);
      //}); 
      //this.commitsTarget.innerHTML = commitsHTML;
  //  });
 // }


    }


}
