import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for ExampleReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {

  updateclock() {
    var currentdate = new Date();
    var time= currentdate.getHours() + ":" + ("0" + currentdate.getMinutes()).slice(-2);
    try {
      document.getElementById("clock").innerHTML=time;
    } catch (e) {
      clearInterval(this.clock_interval);
    }
  }

  startRefreshing() {
    this.clock_interval = setInterval(() => {
      this.updateclock()
    }, 500)
  }

  connect() {
    this.updateclock()
    this.startRefreshing() 
  }
}
