
import { Modal } from "tailwindcss-stimulus-components"

import StimulusReflex from 'stimulus_reflex'


export default class extends Modal {
  static targets = ['container','container2'];


  connect () {
    super.connect();
    StimulusReflex.register(this)
  }
  
  edit (event) {
    console.log("Edit")
    console.log(event.target.dataset)
    $('#shiftEditorShiftId').val(event.target.dataset.shiftid)
    $('#shiftEditorStart').val(event.target.dataset.start)
    $('#shiftEditorEnd').val(event.target.dataset.end)
    $('#shiftEditorEmployee').val(event.target.dataset.employee)
    $('#shiftEditorDay').val(event.target.dataset.day)
    if (event.target.dataset.shiftid) {
      document.getElementById('shiftDeleteButton').dataset.sid=event.target.dataset.shiftid
      $('#shiftDeleteButton').show()
    } else { 
      $('#shiftDeleteButton').hide()
    }
    this.open(event)
  }
  editPS (event) {
    console.log("EditPS")
    console.log(event.target)
    $('#popularShiftEditorShiftId').val(event.target.dataset.shiftid)
    $('#popularShiftEditorStart').val(event.target.dataset.start)
    $('#popularShiftEditorEnd').val(event.target.dataset.end)
    if (event.target.dataset.shiftid) {
      document.getElementById('popularShiftDeleteButton').dataset.sid=event.target.dataset.shiftid
      $('#popularShiftDeleteButton').show()
    } else { 
      $('#popularShiftDeleteButton').hide()
    }
    this.open2(event)
  }

  open2(e) {
    if (this.preventDefaultActionOpening) {
      e.preventDefault();
    }

    e.target.blur();

    // Lock the scroll and save current scroll position
    this.lockScroll();

    // Unhide the modal
    this.container2Target.classList.remove(this.toggleClass);

    // Insert the background
    if (!this.data.get("disable-backdrop")) {
      document.body.insertAdjacentHTML('beforeend', this.backgroundHtml);
      this.background = document.querySelector(`#${this.backgroundId}`);
    }
  }

  close2(e) {
    if (e && this.preventDefaultActionClosing) {
      e.preventDefault();
    }

    // Unlock the scroll and restore previous scroll position
    this.unlockScroll();

    // Hide the modal
    this.container2Target.classList.add(this.toggleClass);

    // Remove the background
    if (this.background) { this.background.remove() }
  }
  
    
  dragPopularShift(event) {
    event.dataTransfer.setData('text/plain', event.target.id)
    event.dataTransfer.effectAllowed = "copy"
  }
  drop(event) {
    event.preventDefault() 
    const data = event.dataTransfer.getData("text/plain")
    const employee=event.target.dataset.employee
    const day=event.target.dataset.day
    console.log("Drop",day,employee,data)
    try {
      this.stimulate("SchedulesReflex#drop",employee,day,data)
    } catch(err) {
	  console.log(err);
    }
  }
  dragover(event) {
    event.preventDefault() ;
  }
   beforeSubmitShiftEditor(element, reflex) {
     this.close()
   }
   beforeSubmitPopularShiftEditor(element, reflex) {
     this.close2()
   }
   submitShiftEditorError(element, reflex,error) {
	alert("Error: invalid entry");
   }
   submitPopularShiftEditorError(element, reflex,error) {
	alert("Error: invlaid entry");
   }

   afterPreviousWeek(element) {
    console.log('AFTEr WEEK');
       $('.edittr').editable(
      function(value, settings) {
        console.log('saving');
        document.getElementById('attendance')['attendance'].saveTR(this,value);
        return(value);
      },{
        width: '156px'
      }
    )
    console.log('reiniting editable');

   }
   afterNextWeek(element) {
    console.log('AFTEr WEEK');
       $('.edittr').editable(
      function(value, settings) {
        console.log('saving');
        document.getElementById('attendance')['attendance'].saveTR(this,value);
        return(value);
      },{
        width: '56px'
      }
    )
    console.log('reiniting editable');

   }

}
