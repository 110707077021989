import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for ScheduleReflexReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  connect () {
    console.log("Schedule Reflex");
    super.connect()
   }

   beforeSubmitShiftEditor(element, reflex) {
	   alert("before");
   }

   submitShiftEditorSuccess(element, reflex) {
	   alert('hello');
    $('#shiftEditor').foundation('close');
    $('#popularShiftEditor').foundation('close');
   }

   submitShiftEditorError(element, reflex) {
	   alert('hello');
    $('#shiftEditor').foundation('close');
    $('#popularShiftEditor').foundation('close');
   }

   afterPreviousWeek(element) {
    console.log('AFTEr WEEK');
   }
}
