// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import StimulusReflex from 'stimulus_reflex'
import consumer from '../channels/consumer'
import controller from './application_controller'

//const application = Application.start()
//const context = require.context("controllers", true, /_controller\.js$/)
//application.load(definitionsFromContext(context))

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
const context_components = require.context("../../components", true, /_controller\.js$/)
application.load(
  definitionsFromContext(context).concat( definitionsFromContext(context_components))
)

StimulusReflex.initialize(application, { consumer, controller, debug: false })

import { Tabs } from "tailwindcss-stimulus-components"
application.register('tabs', Tabs)

import { Modal } from "tailwindcss-stimulus-components"
application.register('modal', Modal)

StimulusReflex.debug = process.env.RAILS_ENV === 'development'

